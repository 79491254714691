.login {
  box-sizing: border-box;
  height: 100vh;
  display: flex;
  justify-content: center;

  .loginCard {
    box-sizing: border-box;
    width: 400px;
    // padding: 80px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .login_header {
      h2 {
        font-size: 36px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: center;
        margin: 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
      }
    }
    .login_form {
      width: 100%;
    }
    .MuiInputBase-root,
    .MuiButtonBase-root {
      border-radius: 10px !important;
    }
    .MuiInputLabel-root,
    .MuiButtonBase-root,
    .MuiTypography-root {
      font-family: "Poppins", sans-serif !important;
    }

    .login__button {
      background-color: #9f42e4;
      border-radius: 10px;
    }
  }
}
