.signup {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 20px;
  // margin-bottom: 20px;
  .signupCard {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    gap: 20px;
    margin-top: 20px;
    .signup_header {
      h2 {
        font-size: 36px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: -0.02em;
        text-align: center;
        margin: 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0;
      }
    }
    .signup_form {
      width: 100%;
    }
    .MuiInputBase-root,
    .MuiButtonBase-root {
      border-radius: 10px !important;
    }
    .MuiInputLabel-root,
    .MuiButtonBase-root,
    .MuiTypography-root {
      font-family: "Poppins", sans-serif !important;
    }

    .signup__button {
      background-color: #9f42e4;
      border-radius: 10px;
    }
  }
}
