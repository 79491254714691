.feedback-container {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 56px 24px;
  height: calc(100% - 100px);

  .content-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .title-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: auto;
      .title-bg {
        font-family: Poppins;
        font-size: 30px;
        font-weight: 500;
        line-height: 38px;
        text-align: left;
        color: #111322;
      }
    }
    .details-wrapper {
      display: flex;
      align-items: center;
      gap: 30px;
      padding: 0;
      li:first-child {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 6px;
      }
      li {
        //styleName: Text sm/Normal;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #5d6b98;
      }
    }
  }
  .assessment-title {
    border-bottom: 1px solid #dcdfea;
    padding-bottom: 16px;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: #111322;
  }

  .assessment-container {
    display: flex;
    gap: 10px;
    height: auto;
    max-height: calc(100% - 250px);
    .video-container {
      display: flex;
      flex: 1;
      background: black;
      border-radius: 16px;
      width: 100%;
    }
  }
}
