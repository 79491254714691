$gray: #dcdfea;

.layout-container {
  background-color: $gray;
  display: flex;
  align-items: start;
  justify-content: start;
  padding: 24px;
  overflow: hidden;

  .children-wrapper {
    background-color: white;
    margin-left: 24px;
    width: 100%;
    height: calc(100vh - 48px);
    border-radius: 30px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
