.page-container {
  background-color: #eef0f6;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

.welcome-container {
  background-color: white;
  padding: 50px;
  gap: 16px;
  border-radius: 30px;
  width: 500px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-img {
  height: 38px;
  width: 168px;
  object-fit: contain;
}

.welcome-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.welcome-title {
  font-family: Poppins;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #111322;
}
.welcome-subtitle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #111322;
}
