.banner {
  width: 650px;
  padding: 68px;
  box-sizing: border-box;
  color: white;

  h2 {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
  h4 {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
  p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: justify;
  }
  .header-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 24px;
    h3 {
      margin: 0;
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
  }
}
