$white: #fff;

.outisde-layout-container {
  background-color: $white;
  box-sizing: border-box;
  display: flex;
  min-height: 100vh;
  gap: 24px;
  .banner-container {
    flex: 5;
    background: linear-gradient(#200454a0, #200454a0),
      url("../../public/assets/images/banner2.png") no-repeat left / cover;
  }
  .children-wrapper {
    flex: 4;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
  }
}
