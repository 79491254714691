$light-gray: #eef0f6;
$white: #fff;
$black: #2c3e50;

.sidebar-expanded {
  width: 267px;
}
.sidebar-not-expanded {
  width: 86px;
}
.sidebar-container {
  // position: relative;
  transition-property: width;
  transition-timing-function: ease;
  transition-duration: 300ms;
  height: calc(100svh - 48px);
  .sidebar-nav {
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: space-between;
    // gap: 44px;
    border-right-width: 1px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: $white;
    padding: 56px 16px;
    border-radius: 30px;

    .logo-container {
      height: 36px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo-sidebar {
        overflow: hidden;
        transition-property: all;
        transition-timing-function: ease;
        transition-duration: 1000ms;
        // transition: opacity 0.3s ease-in-out;
      }
      .opacity-0 {
        opacity: 0;
        width: 0;
      }
      .opacity-1 {
        opacity: 1;
      }
    }
    .line-container-expanded {
      width: 147px;
    }
    .line-container-not-expanded {
      width: 10px;
    }
    .line-container {
      padding: 20px 39px 10px !important;
      transition-property: all;
      transition-timing-function: ease;
      transition-duration: 300ms;
      .line {
        height: 1px;
        background-color: $light-gray;
      }
    }
    .sidebar-header {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: calc(100% - 56px);
      .sidebar-body {
        
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
      }
      .sidebar-footer {
        display: flex;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        border-top-width: 1px;
      }
    }
  }
}
